@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/mixins";

// LE COLOR SCHEME
$colorCharcoal: #454545;
$colorStone: #DAD7CF;
$colorFog: #F3F1EB;
$colorRed: #C4213D;
$colorAccessibleGray: #747474;
$colorSoftGray: #8f8f8f;
$colorOutlineGray: #BBBBBB;
$colorDarkBlue: #002144;
$colorBlue: #369;
$colorActionBlue: #29a7dc;
$colorInfoOrange: #B7521F;
$colorWarningHover: darken($colorInfoOrange, 10);
$colorInfoBlue: #31708f;
$colorInfoGreen: #356f70;
$colorDark: #333;
$colorLight: $colorStone;
$colorSearchSuggestionActive: #e8e8e8;
$colorDisabled: $colorSoftGray;
$colorDisabledBg: #888;
$colorSale: $colorRed;
$colorLinkSecondary: #337ab7;
$colorError: $colorRed;
$colorStateErrorBg: #F7E5E6;
$colorSuccess: #257E69;
$colorSuccessBg: #F4F6F3;
$colorStateSuccess: #3c763d;
$colorStateSuccessBg: #dff0d8;
$colorSand: #FDFBF9;
$colorSavings: $colorRed;
$colorPanelInfo: #002144;
$colorPanelInfoBg: #ebf2f3;
$colorPanelWarningBg: #ffe0bb;
$colorHoverPrimary: #f5f5f5;
$colorTitle: #eee;
$colorTitleSuccess: #dff0d8;
$colorSubtitle: #f9f9f9;
$colorInputError: #fcebeb;
$colorInputWarning: #fcebe1;
$colorBorderError: #dc3545;
$colorLightBlue: #D6EBFF;
$colorGrey: #727272;
$colorMuted: #777777;
$colorInfoLight: #E8F4F9;
$colorInfoMedium: #D0E8F2;
$colorInfoStrong: #95CAE1;
$colorLightYellow: #fff6ed;
$colorBreadcrumbActive: #575757;
$colorLogoBg: #fbf9f0;
$colorImagePreLoader: #f2f2f2;
$colorReactiveChat: $colorStone;
$colorRecaptcha: #4A90E2;
$colorFooterMenu: #EFEFEF;
$colorMobileHeader: #f3f3f3;
$colorPopoverInfo: #2C5E7D;
$lightGrey: #dcdee9;

// FOREGROUNDS
$foregroundPrimary: $colorCharcoal;
$foregroundPrimaryHover: darken($colorCharcoal, 20);
$foregroundSecondary: $colorAccessibleGray;
$foregroundInverse: $colorSand;
$foregroundFooter: $colorDark;
$backgroundSearchSuggestionActive: $colorSearchSuggestionActive;
$foregroundLink: $colorCharcoal;
$foregroundLinkActive: $colorBlue;
$foregroundLinkSecondary: $colorLinkSecondary;
$foregroundError: $colorError;
$foregroundWarning: $colorInfoOrange;
$foregroundWarningSecondary: $colorCharcoal;
$foregroundSuccess: $colorSuccess;
$foregroundStateSuccess: $colorStateSuccess;
$foregroundDisabled: $colorDisabled;
$foregroundSale: $colorSale;
$foregroundPanelInfo: $colorPanelInfo;
$foregroundSavings: $colorSavings;
$foregroundHeading: $colorDarkBlue;
$foregroundMuted: $colorMuted;
$foregroundInfo: $colorInfoBlue;
$foregroundHeaderIcons: $colorGrey;
$foregroundInfoStrong: $colorInfoStrong;

//BACKGROUNDS
$backgroundPrimary: $colorSand;
$backgroundSecondary: $colorFog;
$backgroundTertiary: $colorStone; // TODO: refactor / replace vars below with Secondary and Tertiary metaphors
$backgroundInverse: $colorCharcoal;
$backgroundHeader: $colorSand;
$backgroundFooter: $colorSand;
$backgroundFooterMenu: $colorFooterMenu;
$backgroundHoverPrimary: $colorHoverPrimary;
$backgroundCallout: $colorBlue;
$backgroundTitle: $colorTitle;
$backgroundSubTitle: $colorSubtitle;
$backgroundInputError: $colorInputError;
$backgroundInputWarning: $colorInputWarning;
$backgroundInfo: $colorInfoGreen;
$backgroundDisabled: $colorDisabledBg;
$backgroundPanelInfo: $colorPanelInfoBg;
$backgroundPanelWarning: $colorPanelWarningBg;
$backgroundSuccess: $colorSuccessBg;
$backgroundStateSuccess: $colorStateSuccessBg;
$backgroundStateError: $colorStateErrorBg;
$backgroundWarning: $colorInfoOrange;
$backgroundWarningHover: $colorWarningHover;
$backgroundWarningBox: $colorLightYellow;
$backgroundError: $colorError;
$backgroundPanelSecondary: $colorLightBlue;
$backgroundInfoLight: $colorInfoLight;
$backgroundInfoMedium: $colorInfoMedium;
$backgroundInfoStrong: $colorInfoStrong;
$backgroundKitContent: $colorAccessibleGray;
$backgroundLogo: $colorLogoBg;
$backgroundImagePreLoader: $colorImagePreLoader;
$backgroundReactiveChatButton: $colorReactiveChat;
$backgroundRecaptcha: $colorRecaptcha;
$backgroundPopoverInfo: $colorPopoverInfo;
$backgroundLogo: $lightGrey;

//SOCIAL COLORS - TODO: remove when working on Checkout BR
$socialBlogColor: $colorActionBlue;

//BORDERS
$borderPrimary: $colorOutlineGray;
$borderSecondary: $colorStone;
$borderTertiary: $colorCharcoal;
$borderTertiaryHover: darken($colorCharcoal, 20);
$borderBtnFocus: $colorStone;
$borderPanelInfo: $colorPanelInfo;
$borderSand: $colorSand;
$borderSuccessDark: $colorStateSuccess;
$borderDangerDark: $colorError;
$borderDisabled: $colorStone;
$borderWarning: $colorInfoOrange;
$borderError: $colorBorderError;

//BOX SHADOWS
$borderBoxShadowPrimary: transparentize($borderPrimary, 0.4);
$borderBoxShadowError: transparentize($borderError, 0.75);
$boxShadowInputPrimary: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $borderBoxShadowPrimary;
$boxShadowInputSecondary: 0 0 0 .25rem transparentize($borderTertiary, 0.9);
$boxShadowInputError: 0 0 0 0.2rem $borderBoxShadowError;

//BUTTONS
$backgroundBtnPrimary: $colorCharcoal;
$backgroundBtnPrimaryHover: darken($backgroundBtnPrimary, 10);
$backgroundBtnSecondary: $colorAccessibleGray;
$backgroundBtnSecondaryHover: darken($backgroundBtnSecondary, 10);
$backgroundBtnTertiary: $colorSand;
$backgroundBtnSuccess: $colorSuccess;
$foregroundBtnAction: $colorDarkBlue;
$backgroundBtnDisabled: $colorFog;
$backgroundBtnIcon: $colorFog;
$backgroundBtnIconHover: darken($backgroundBtnIcon, 10);

// FONTS
$font-family-base: 'GT Alpina Light', Helvetica, Arial, sans-serif;
$font-family-caption: 'Sofia Pro Regular', Helvetica, Arial, sans-serif;
$font-family-caption-bold: 'Sofia Pro Semibold', Helvetica, Arial, sans-serif;
$font-family-interactive: 'Sofia Pro Regular', 'Helvetica Neue', sans-serif;
$font-family-interactive-caps: 'Sofia Pro Medium', 'Helvetica Neue', sans-serif;
$font-size-xl: 20px;
$font-size-lg: 18px;
$font-size-default: 16px;
$font-size-md: 14px;
$font-size-sm: 12px;
$font-size-xs: 10px;
$font-awesome: normal normal 900 14px 'Font Awesome 6 Free';

@mixin fontCreation($fontFamily, $fontSize, $lineHeight, $letterSpacing, $textTransform: none) {
  font: normal normal normal $fontSize $fontFamily;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  text-transform: $textTransform;
}

@mixin font-heading($fontSize: 18px) {
  @include fontCreation($font-family-base, $fontSize, 1.2, 0, capitalize);
}

@mixin font-body($fontSize: $font-size-default) {
  @include fontCreation($font-family-base, $fontSize, 1.4, 0);
}

@mixin font-caption($fontSize: $font-size-default, $lineHeight: 1.4) {
  @include fontCreation($font-family-caption, $fontSize, $lineHeight, 0);
}

@mixin font-caption-bold($fontSize: $font-size-default) {
  @include fontCreation($font-family-caption-bold, $fontSize, 1.4, 0);
}

@mixin font-interactive($fontSize: $font-size-default, $lineHeight: normal) {
  @include fontCreation($font-family-interactive, $fontSize, $lineHeight, 0.01em);
}

@mixin font-interactive-caps($fontSize: $font-size-default) {
  @include fontCreation($font-family-interactive-caps, $fontSize, normal, 0.08em, uppercase);
}

//NAVBAR
$navbarBg: $colorDarkBlue;
$navbarHeight: 40px;


$marginSizeBig: 30px;
$marginSizeBasic: 15px;
$marginSizeSmall: 5px;

// BORDER RADIUS
$border-radius-base: 8px;
$border-radius-round: 50%;

//CATALOG
$gridOverlayColor: $colorActionBlue;

//CUSTOM BREAKPOINTS
$tablet-device-size: 950px;
$ipad-pro-width: 1367px;

//Bootstrap variables overrides
$spacers: map-merge(
    (
      6: ($spacer * 4), //64px
      7: ($spacer * 5), //80px
      8: ($spacer * 6.25), //100px
      9: ($spacer * 7.5), //120px
      10: ($spacer * 9.375) //150px
    ),
    $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

.breadcrumb {
  --bs-breadcrumb-padding-x: 1rem;
  --bs-breadcrumb-padding-y: 0.75rem;
}

.table {
  --bs-table-bg: transparent;
}

$link-decoration: none;
$link-hover-decoration: underline;
$btn-close-width: 0.75em;
